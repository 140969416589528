<template>
  <ShopNav />
</template>

<script>
import ShopNav from "@/components/ShopNav.vue";

export default {
  components: { ShopNav },
};
</script>

<style scoped></style>
